//VARIABLES
$titre-nom-guitarist: #ffb703;
$background: #30638e;
$card-background: #023047;
$card-details: #fff;

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  background: $background;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header {
  font-family: "JetBrains Mono", sans-serif;
  color: $titre-nom-guitarist;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  h1 {
    font-size: 2.5em;
  }
  h4 {
    // compteur de guitaristes
    color: $titre-nom-guitarist;
  }
}

.posts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  h1 {
    text-align: center;
    margin: 10px 0;
  }
}

nav {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

.control {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  width: 30%;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-top: 0.5rem;
}

.radioSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  border-radius: 5px;
  border: 2px solid $titre-nom-guitarist;
  padding: 0.4rem;
  background: $card-background;
  color: $titre-nom-guitarist;
  min-width: 100%;
}

.MuiButton-containedPrimary svg {
  padding-right: 3px;
}

.MuiInputBase-input {
  background: $card-details !important;
  border-radius: 5px;
  width: 100% !important;
}

.card {
  margin: 7px;
  background: $card-background !important;
  border-radius: 10px !important;
  h1 {
    text-align: start;
    color: $titre-nom-guitarist;
  }
  h2,
  h3,
  span {
    color: $card-details;
  }
}

.button-back {
  margin-top: 10px !important;
  background: #ffb703 !important;
  color: $background !important;
}

.cardPageImg {
  padding-top: 10px;
}

.cardBio {
  color: #ffffff;
  p {
    margin-top: 7px;
  }
}

.cardBioH1 {
  margin-bottom: 8px;
  color: $titre-nom-guitarist;
  font-family: "JetBrains Mono", sans-serif;
}
.cardBioInfoH4 {
  margin-bottom: 8px;
  font-weight: normal;
  color: #ffffff;
}

.cardBioH4 {
  margin-top: 14px;
  color: #ffffff;
}

.cardImg {
  border-radius: 7px;
  border: 1px solid #292928;
  min-width: 373px;
  max-width: 100%;
  height: auto;
}

.main-infos {
  h1 {
    margin-top: 0;
  }
}

.savoir-plus {
  margin: 0;
  color: $titre-nom-guitarist !important;
  font-weight: 600 !important;
}

.savoir-plus span {
  padding-left: 5px;
  color: $titre-nom-guitarist !important;
  font-weight: 600 !important;
}

.go-wiki {
  margin: 1rem 0;
  color: $titre-nom-guitarist !important;
  font-weight: 600 !important;
}

.go-wiki span {
  padding-left: 5px;
  color: $titre-nom-guitarist !important;
  font-weight: 600 !important;
}

form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.admin {
  width: 70%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  background: $card-details;
  border-radius: 10px;
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
}

.footer {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background: $card-background;
  color: $card-details;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  position: absolute;
  bottom: auto;
}

// ****************************************** MEDIA QUERY ******************************************

@media screen and (max-width: 820px) {
  .search {
    width: 95%;
  }
  .control {
    width: 45%;
  }
  .footer {
    display: none;
  }
}
